import jQuery from 'jquery';
const $ = jQuery.noConflict();
let apiUrl = process.env.VUE_APP_API_URL;
export default class SearchSeeker {
  static getListServicePeople(obj, self) {
    $.ajax({
      type: 'POST',
      url: apiUrl + 'search/traveller-people',
      // data: {data: obj, page: pagePeople},
      data: obj,
    })
      .done(function (res) {
        self.$data.ajax.canClickSearch = true;
        // If successful
        console.log(res.items);
        // this.$data.ajaxLoad.loginNormal = true
        self.$data.resultArray = [];

        if (res.items.length > 0) {
          self.$data.resultArray = res.items;
          // self.$data.initGmap.center.lat = originalLatitude;
          // self.$data.initGmap.center.long = originalLongitude;//eslint-disable
          self.$data.common.pagePeople++; //eslint-disable
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // If fail
        console.log(textStatus + ': ' + errorThrown);
      });
  }
  static showMoreServicePeople(obj, self) {
    console.log('canLoadPeople : ', self.$data.ajax.canLoadPeople);
    self.$data.ajax.canLoadPeople = false;
    $.ajax({
      type: 'POST',
      url: apiUrl + 'search/traveller-people',
      data: obj,
    })
      .done(function (res) {
        // If successful
        console.log('showMoreServicePeople ', res.items);
        // this.$data.ajaxLoad.loginNormal = true
        if (res.items.length > 0) {
          for (var i = 0; i < res.items.length; i++) {
            self.$data.resultArray.push(res.items[i]);
          }

          self.$data.common.pagePeople++;
          self.$data.ajax.canLoadPeople = true;
        } else {
          self.$data.ajax.canLoadPeople = false;
        }
        self.$data.ajax.isLoadedShowMore = false;
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // If fail
        console.log(
          textStatus + ' : ' + errorThrown + ' canLoadPeople : ' + self.$data.ajax.canLoadPeople
        );
        self.$data.ajax.canLoadPeople = false;
        self.$data.ajax.isLoadedShowMore = false;
      });
  }

  static getListServicePackage(obj, self) {
    $.ajax({
      type: 'POST',
      url: apiUrl + 'search/traveller-package',
      // data: {data: obj, page: pagePeople},
      data: obj,
    })
      .done(function (res) {
        self.$data.ajax.canClickSearch = true;
        // If successful
        console.log(res.items);
        // this.$data.ajaxLoad.loginNormal = true
        self.$data.resultArray = [];

        if (res.items.length > 0) {
          self.$data.resultArray = res.items;
          // self.$data.initGmap.center.lat = originalLatitude;
          // self.$data.initGmap.center.long = originalLongitude;//eslint-disable
          self.$data.common.pagePackage++; //eslint-disable
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // If fail
        console.log(textStatus + ': ' + errorThrown);
      });
  }
  static showMoreServicePackage(obj, self) {
    console.log('canLoadPeople : ', self.$data.ajax.canLoadPackage);
    self.$data.ajax.canLoadPackage = false;
    $.ajax({
      type: 'POST',
      url: apiUrl + 'search/traveller-package',
      data: obj,
    })
      .done(function (res) {
        // If successful
        console.log('showMoreServicePackage ', res.items);
        // this.$data.ajaxLoad.loginNormal = true
        if (res.items.length > 0) {
          for (var i = 0; i < res.items.length; i++) {
            self.$data.resultArray.push(res.items[i]);
          }

          self.$data.common.pagePackage++;
          self.$data.ajax.canLoadPackage = true;
        } else {
          self.$data.ajax.canLoadPackage = false;
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // If fail
        console.log(
          textStatus + ' : ' + errorThrown + ' canLoadPackage : ' + self.$data.ajax.canLoadPackage
        );
        self.$data.ajax.canLoadPackage = false;
      });
  }
  static getListServiceProduct(obj, self) {
    $.ajax({
      type: 'POST',
      url: apiUrl + 'search/traveller-product',
      // data: {data: obj, page: pagePeople},
      data: obj,
    })
      .done(function (res) {
        self.$data.ajax.canClickSearch = true;
        // If successful
        console.log(res.items);
        // this.$data.ajaxLoad.loginNormal = true
        self.$data.resultArray = [];

        if (res.items.length > 0) {
          self.$data.resultArray = res.items;
          // self.$data.initGmap.center.lat = originalLatitude;
          // self.$data.initGmap.center.long = originalLongitude;//eslint-disable
          self.$data.common.pageProduct++; //eslint-disable
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // If fail
        console.log(textStatus + ': ' + errorThrown);
      });
  }
  static showMoreServiceProduct(obj, self) {
    console.log('canLoadPeople : ', self.$data.ajax.canLoadProduct);
    self.$data.ajax.canLoadProduct = false;
    $.ajax({
      type: 'POST',
      url: apiUrl + 'search/traveller-product',
      data: obj,
    })
      .done(function (res) {
        // If successful
        console.log('showMoreServiceProduct ', res.items);
        // this.$data.ajaxLoad.loginNormal = true
        if (res.items.length > 0) {
          for (var i = 0; i < res.items.length; i++) {
            self.$data.resultArray.push(res.items[i]);
          }

          self.$data.common.pageProduct++;
          self.$data.ajax.canLoadProduct = true;
        } else {
          self.$data.ajax.canLoadProduct = false;
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        // If fail
        console.log(
          textStatus + ' : ' + errorThrown + ' canLoadPackage : ' + self.$data.ajax.canLoadProduct
        );
        self.$data.ajax.canLoadProduct = false;
      });
  }
}
// module.exports.SearchSeeker = SearchSeeker
