<template>
  <div class="container-fluid d-flex flex-column d-flex flex-fill">
    <div
      id="search-header-wrapper"
      class="row search-header-wrapper gy-1 d-none d-lg-block"
    >
      <div class="col-sm-12">
        <span class="fw-bolder bg-dark text-wrap text-white">People Service </span>
        – Companion to assist you at the airport or in-flight.
        <span class="fw-bolder bg-dark text-wrap text-white"> Package Service</span>
        –  Courier to ship your packages to destination.
        <span class="fw-bolder bg-dark text-wrap text-white"> Product Service </span>– Shopping concierge to buy products overseas and deliver to you
      </div>
    </div>
    <div
      id="search-block-input-wrapper"
      class="row search-block-input-wrapper gy-md-3 gy-lg-0 mt-sm-3 mt-lg-0"
    >
      <div
        v-if="commonErrors.length > 0"
        class="col-sm-12"
      >
        <ErrorFormField :msg-array="commonErrors" />
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3">
        <label class="label-require">Trepr Services</label>
        <!--        v-model="service"-->
        <select
          id="service"
          class="form-select"
          @change="onServiceChange"
        >
          <option
            value=""
            :selected="$route.query.service == undefined"
          >
            Please select a service
          </option>
          <option
            value="people"
            :selected="$route.query.service == 'people'"
          >
            People
          </option>
          <option
            value="package"
            :selected="$route.query.service == 'package'"
          >
            Package
          </option>
          <option
            value="product"
            :selected="$route.query.service == 'product'"
          >
            Product
          </option>
        </select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label
          for="rangeDatetimePicker"
          class="label-require"
        >Date Time</label>
        <input
          id="rangeDatetimePicker"
          type="text"
          class="form-control"
          placeholder="Date Time"
        >
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label
          for="originalLocation"
          class="label-require"
        >Original Location</label>

        <div class="input-group">
          <span class="input-group-text">
            <div
              v-if="$data.ajax.originalLocationLoad"
              class="spinner-border spinner-border-sm text-secondary"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <i
              v-else
              class="fas fa-plane-departure text-danger"
            />
          </span>
          <input
            id="originalLocation"
            type="text"
            class="form-control"
            placeholder="Original Location"
            autocomplete="off"
            :value="$route.query.origin"
          >
        </div>

        <input
          id="origin_type"
          type="hidden"
          :value="$route.query.origin_type"
        >
        <input
          id="origin_code"
          type="hidden"
          :value="
            $route.query.origin != undefined ? $route.query.origin.substring(1, 4) : ''
          "
        >
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label
          for="originalLocation"
          class="label-require"
        >Destination Location</label>
        <div class="input-group">
          <span class="input-group-text">
            <LoadingIcons
              v-if="$data.ajax.destinationLocationLoad"
              total="1"
            />
            <i
              v-else
              class="fas fa-plane-arrival text-danger"
            />
          </span>
          <input
            id="destinationLocation"
            type="text"
            class="form-control"
            placeholder="Destination Location"
            autocomplete="off"
            :value="$route.query.destination"
          >
        </div>

        <input
          id="destination_type"
          type="hidden"
          :value="$route.query.destination_type"
        >
        <input
          id="destination_code"
          type="hidden"
          :value="
            $route.query.destination != undefined
              ? $route.query.destination.substring(1, 4)
              : ''
          "
        >
      </div>
    </div>
    <div
      v-if="$data.service == 'people'"
      id="optionPeople"
      class="row"
    >
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label>No of stops (optional)</label>
        <select
          id="peopleNoOfStop"
          class="form-select"
        >
          <option value="">
            Please select a no of stops
          </option>
          <option value="0">
            Direct
          </option>
          <option
            v-for="item in [1, 2, 3, 4, 5, 6, 7]"
            :key="{ item }"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="$data.service == 'package'"
      id="optionPackage"
      class="row"
    >
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label>Package weight (Optional)</label>
        <div class="row g-1">
          <div class="col-sm-9">
            <select
              id="package_weight"
              class="form-select"
            >
              <option
                value=""
                selected
              >
                Select package weight
              </option>
              <option value="1-5">
                1 - 5
              </option>
              <option value="5-10">
                5 - 10
              </option>
              <option value="10-15">
                10 - 15
              </option>
              <option value="15-20">
                15 - 20
              </option>
              <option value="20-30">
                20 - 30
              </option>
              <option value="30-40">
                30 - 40
              </option>
              <option value="40-50">
                40 - 50
              </option>
              <option value="50+">
                50+
              </option>
            </select>
          </div>
          <div class="col-sm-3">
            <select
              id="package_weight_unit"
              class="form-select"
            >
              <option value="kg">
                kg
              </option>
              <option value="lbs">
                lbs
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$data.service == 'product'"
      id="optionProduct"
      class="row"
    >
      <div class="col-sm-12 col-md-6 col-lg-3">
        Product category (Optional)
        <select
          id="product_category"
          class="form-select"
        >
          <option value="">
            Please select product category
          </option>
          <option
            v-for="item in $data.productCategories"
            :key="item.ID"
            :value="item.ID"
          >
            {{ item.subcatname }}
          </option>
        </select>
      </div>
    </div>
    <div
      id="search-block-confirm-wrapper"
      class="row search-block-confirm-wrapper mt-1 mb-lg-3"
    >
      <div class="col-6">
        <button
          v-if="$data.ajax.canClickSearch"
          id="btnSearch"
          type="submit"
          class="btn btn-seeker text-dark"
          @click="btnSearchClick"
        >
          Search
        </button>
        <LoadingIcons v-else />
      </div>
      <div class="col-6 d-flex justify-content-end d-lg-none">
        <div
          id="resultGroup"
          class="btn-group d-none"
          role="group"
        >
          <button
            id="btnResultList"
            type="button"
            class="btn btn-danger"
            @click="btnResultListClick"
          >
            List
          </button>
          <button
            id="btnResultMap"
            type="button"
            class="btn"
            @click="btnResultMapCLick"
          >
            Map
          </button>
        </div>
      </div>
    </div>

    <div
      id="searchResult"
      class="row search-result flex-fill d-none g-0"
    >
      <div
        id="searchResultList"
        class="col-md-12 col-lg-4 col-xl-3 pr-lg-2"
      >
        <ul
          v-if="$data.resultArray.length > 0"
          id="ulSearchResultList"
          class="list-group list-group-flush"
          @scroll="handleScroll"
        >
          <ResultItem
            v-for="(item, index) in $data.resultArray"
            :key="{ index }"
            :item="{ item }"
          />
          <li
            v-if="$data.ajax.isLoadedShowMore"
            class="list-group-item text-center"
          >
            <!--          <li class="list-group-item text-center">-->
            <LoadingIcons total="7" />
          </li>
        </ul>
        <div v-else>
          <h3>No result</h3>
        </div>
      </div>
      <div
        id="searchResultMap"
        class="col-md-12 col-lg-8 col-xl-9 d-lg-block d-flex"
        style=""
      >
        <div
          v-if="$data.resultArray.length > 0"
          class="map-frame flex-fill"
        >
          <!--                    <MyGoogleMap />-->
          <MyGoogleMap
            :center="{
              lat: $data.initGmap.center.lat,
              lng: $data.initGmap.center.long,
            }"
          />
        </div>
      </div>
    </div>
    <div class="my-3 ">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2 class="text-primary">
            Please download Trepr mobile app pand share your <b>Companionship</b> , <b>Courier</b>  or <b>Concierge</b>  requirements
          </h2>
          <h4 class="text-secondary">
            Download Trepr, the app helps you find ways to earn in your international flight journey.
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 offset-md-2 col-lg-3 offset-lg-3  col-sm-6 pt-1">
          <a
            class="w-100 h-100"
            href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.trepr"
            target="_blank"
            rel="noopener"
          >
            <img
              src="/landing/august2021/img/home/google-play-badge.png"
              class="img-fluid w-100 h-100"
              alt="google play"
            >
          </a>
        </div>
        <div class="col-lg-3 col-sm-6">
          <a
            class="w-100 h-100"
            href="https://apps.apple.com/us/app/trepr/id1504489267?itsct=apps_box_link&itscg=30200"
            target="_blank"
            rel="noopener"
          >
            <img
              src="/landing/august2021/img/home/app-store-badge.svg"
              class="img-fluid w-100 h-100"
              alt="app store"
            >
          </a>
        </div>
      </div>
      <!--      <div class="col-sm-12 text-center">-->
      <!--        <h1>About Us</h1>-->
      <!--      </div>-->
      <!--      <div class="col-sm-12 lead text-justify">-->
      <!--        Founded in August of 2015 and based in Belfast, Northern Ireland, UK, Trepr (Trip-->
      <!--        Representative) is one of the peer to peer economy sharing trusted community marketplace for-->
      <!--        people to list, discover, and substitute another person for their travel needs around the-->
      <!--        world.-->
      <!--        <br>-->
      <!--        Almost every person has his/her local or international travel plans either occasionally or-->
      <!--        frequently. Trepr connects flight travelers and service seekers all over the world to-->
      <!--        exchange their travel plan and invoke travel services such as people (Flight Companionship),-->
      <!--        package (Parcel Delivery), and product (Shopping Concierge), safely, cheaply and promptly-->
      <!--        respectively anywhere in the world by substituting a traveler, who travels on the same-->
      <!--        route, and pay for each service through trepr.-->
      <!--      </div>-->
      <!--      <div class="col-sm-12 text-center">-->
      <!--        <button-->
      <!--          type="button"-->
      <!--          class="btn btn-lg btn-secondary mt-3 border-radius-10"-->
      <!--        >-->
      <!--          <router-link-->
      <!--            :to="{ name: 'AboutUs' }"-->
      <!--            class="text-white"-->
      <!--          >-->
      <!--            Read More-->
      <!--          </router-link>-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>

  <!--    <div>-->
  <!--        <h1>test-->
  <!--            :disableUI="false"-->
  <!--            :zoom="12"-->
  <!--            mapType="roadmap"-->
  <!--        </h1>-->
  <!--        <MyGoogleMap-->
  <!--                     :center="{ lat: 38.8977859, long: -77.0057621 }"> </MyGoogleMap>-->
  <!--    </div>-->
</template>

<script type="application/javascript">
/* eslint-disable */
import ErrorFormField from '../../common/error/ErrorFormField';
import LoadingIcons from '../../common/LoadingIcons';
import ResultItem from '../ResultItem';
// import ErrorFormField from "../../common/error/ErrorFormField";
import jQuery from 'jquery';
import MyGoogleMap from '../MyGoogleMap';
import SearchSeekerHandler from './SearchSeeker.js';
import MyAutocomplete from '../MyAutocomplete';

import bootstrap from 'bootstrap';
import SearchTravellerHandler from './SearchSeeker.js';
let autocomplete = require('autocompleter');

window.moment = require('moment');
window.daterangepicker = require('daterangepicker');
const $ = jQuery.noConflict();
// window.$ = $;
//step 2 init date range
// :value="this.$route.query.date"
let start = moment().subtract(60, 'days'); //eslint-disable

// let start = moment();//eslint-disable
// let end = moment().add(7, 'days');//eslint-disable
let end = moment().add(60, 'days'); //eslint-disable
//eslint-disable-nex-line
var // pagePeople = 1,
  // pagePackage = 1,
  // pageProduct = 1,
  // canLoadPeople = true,
  // canLoadProduct = true,
  // canLoadPackage = true,
  isClickedSearch = false;
// marker,
// maplarge,
// originalLatitude,
// originalLongitude;
let apiUrl = process.env.VUE_APP_API_URL;

function cb(start, end) {
  $('#rangeDatetimePicker span').html(
    start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
  );
  // document.getElementById("rangeDatetimePicker").
  // console.log("cb test")
}
//-------------AUto complete--------------------------

var classNameAuto = 'bg-dark text-white list-group list-group-flush';
//-------------END AUto complete--------------------------

function btnSearchClickHandler(self) {
  console.log('btnSearchClickHandler');
  self.$data.ajax.canClickSearch = false;
  let service = document.getElementById('service').value;
  let tmpDate1 = document.getElementById('rangeDatetimePicker').value;
  let destinationLocation = document.getElementById('destinationLocation').value;
  let originalLocation = document.getElementById('originalLocation').value;
  let origin_type = document.getElementById('origin_type').value;
  let destination_type = document.getElementById('destination_type').value;
  self.$data.commonErrors = [];
  if (service.length == 0) {
    self.$data.commonErrors.push('Please choose a service');
  }
  if (tmpDate1.length == 0) {
    self.$data.commonErrors.push('Please choose a date range');
  }

  if (originalLocation.length == 0) {
    self.$data.commonErrors.push('Please fill original location field');
  } else {
    if (origin_type.length == 0) {
      self.$data.commonErrors.push('Please choose original location in the showing list');
    }
  }
  if (destinationLocation.length == 0) {
    self.$data.commonErrors.push('Please fill destination location field');
  } else {
    if (destination_type.length == 0) {
      self.$data.commonErrors.push('Please choose destination location in the showing list');
    }
  }

  if (self.$data.commonErrors.length > 0) {
    self.$data.ajax.canClickSearch = true;
    return false;
  }
  isClickedSearch = true;
  let obj = {
    origin_type: document.getElementById('origin_type').value,
    origin_code: document.getElementById('origin_code').value,
    destination_type: document.getElementById('destination_type').value,
    destination_code: document.getElementById('destination_code').value,
  };
  // obj.item_per_page = 1; //test
  let dateArray = tmpDate1.split(' - ');
  obj.start_date = dateArray[0].trim();
  obj.end_date = dateArray[1].trim();
  if (service == 'people') {
    obj.noofstops = document.getElementById('peopleNoOfStop').value;
    self.$data.common.pagePeople = 1;
    self.$data.ajax.canLoadPeople = true;
    obj.page = self.$data.common.pagePeople;

    SearchSeekerHandler.getListServicePeople(obj, self);
  } else if (service == 'package') {
    obj.package_weight = document.getElementById('package_weight').value;
    obj.package_weight_unit = document.getElementById('package_weight_unit').value;
    self.$data.common.pagePackage = 1;
    self.$data.ajax.canLoadPackage = true;
    obj.page = self.$data.common.pagePackage;
    // console.log("people service", obj);
    SearchSeekerHandler.getListServicePackage(obj, self);
  } else {
    //product
    self.$data.common.pageProduct = 1;
    self.$data.ajax.canLoadProduct = true;
    // obj.product_category = $("#product_category").val();
    obj.product_category = document.getElementById('product_category').value;
    obj.page = self.$data.common.pageProduct;
    // console.log("project service homepage ", obj);
    SearchSeekerHandler.getListServiceProduct(obj, self);
  }

  if (window.screen.width <= 991) {
    //" only hide search block after click with mobile, tablet in veertical mode
    $('#search-block-input-wrapper').toggleClass('d-none');
    $('#btnSearch').toggleClass('d-none');
    $('#openFilter').toggleClass('text-primary');
    if ($('#searchResultList').hasClass('d-none')) {
      $('#searchResultList').removeClass('d-none');
    }
    if (!$('#searchResultMap').hasClass('d-none')) {
      $('#searchResultMap').addClass('d-none');
    }
  }

  if ($('#resultGroup').hasClass('d-none')) {
    $('#resultGroup').removeClass('d-none');
  }

  if ($('#searchResult').hasClass('d-none')) {
    $('#searchResult').removeClass('d-none');
  }
}

function handleScrollHandler() {
  if (!this.$data.ajax.canLoadPeople) return;
  // console.log("search_results scroll " + $("#ulSearchResultList").scrollTop()  + " - " + $("#ulSearchResultList").innerHeight() + " - " + $("#ulSearchResultList")[0].scrollHeight);
  if (!isClickedSearch) return;
  // let tmp = $("#ulSearchResultList").scrollTop() + $("#ulSearchResultList").innerHeight() ;
  // console.log("tmp : " + tmp)
  var self = this;
  if (
    $('#ulSearchResultList').scrollTop() + $('#ulSearchResultList').innerHeight() >=
    $('#ulSearchResultList')[0].scrollHeight
  ) {
    let service = document.getElementById('service').value;
    let tmpDate1 = document.getElementById('rangeDatetimePicker').value;
    console.log(' search_results end reached');
    let obj = {
      origin_type: document.getElementById('origin_type').value,
      origin_code: document.getElementById('origin_code').value,
      destination_type: document.getElementById('destination_type').value,
      destination_code: document.getElementById('destination_code').value,
    };
    obj.item_per_page = 1; //test

    let dateArray = tmpDate1.split(' - ');
    obj.start_date = dateArray[0].trim();
    obj.end_date = dateArray[1].trim();
    self.$data.ajax.isLoadedShowMore = true;
    if (service == 'people') {
      obj.noofstops = document.getElementById('peopleNoOfStop').value;
      self.$data.ajax.canLoadPeople = true;

      obj.page = this.$data.common.pagePeople;
      SearchSeekerHandler.showMoreServicePeople(obj, this);
    } else if (service == 'package') {
      // tmpObj.package_weight = $("#package_weight").val();
      // tmpObj.package_weight_unit = $("#package_weight_unit").val();
      obj.package_weight = document.getElementById('package_weight').value;
      obj.package_weight_unit = document.getElementById('package_weight_unit').value;
      self.$data.ajax.canLoadPackage = true;
      obj.page = this.$data.common.pagePackage;
      SearchSeekerHandler.showMoreServicePackage(obj, this);
    } else {
      //product
      obj.product_category = document.getElementById('product_category').value;
      self.$data.ajax.canLoadProduct = true;
      obj.page = this.$data.common.pageProduct;
      SearchSeekerHandler.showMoreServicePackage(obj, this);
    }
  }
}
//---------------------------------btn search handle
//-----------------------------------------end btn search handle
export default {
  name: 'SearchSeeker',
  layout: {
    name: 'seeker',
  },
  components: { ResultItem, ErrorFormField, MyGoogleMap, LoadingIcons },
  data: function () {
    return {
      service: '',
      commonErrors: [],
      resultArray: [],
      productCategories: [],
      initGmap: {
        // streetViewControl: true,
        // scaleControl: true,
        center: { lat: null, long: null },
        originalLatitude: undefined,
        originalLongitude: undefined,
        // zoom: 2,
      },
      ajax: {
        canClickSearch: true,
        canLoadPeople: true,
        canLoadPackage: true,
        originalLocationLoad: false,
        destinationLocationLoad: false,
        isLoadedShowMore: false,
      },
      common: {
        pagePeople: 1,
        pagePackage: 1,
      },
      isFromHomepage: false,
    };
  },
  computed: {
    showLoadingResultIcon: function () {
      // let ajax = this.$data.ajax
      if (this.$data.ajax.canLoadPeople == true) {
        return true;
      }
      return false;
    },
  },
  methods: {
    btnSearchClick: btnSearchClickHandler,
    handleScroll: handleScrollHandler,
    onServiceChange: function (e) {
      this.$data.service = e.target.value;
      this.$data.resultArray = [];
      this.$data.isLoadedShowMore = false;
    },
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
    let self = this;
    let apiUrl = process.env.VUE_APP_API_URL;
    let token = self.$store.state.token;

    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    fetch(apiUrl + 'common/get-product-category', {
      method: 'GET',
      headers: headersObject,
    })
      .then((response) => response.json())
      .then((resData) => {
        console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data for product category' + resData.message);
          return;
        }
        this.$data.productCategories = resData.productCategories;
      })
      .catch(function (err) {
        console.log('error', err);
      });
    if (this.$route.query.service != undefined) {
      this.$data.service = this.$route.query.service;
      this.$data.isFromHomepage = true;
    }
    //hanlde event from homepage
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    delete window.moment;
    delete window.daterangepicker;
  },
  mounted() {
    document.title = 'Seeker Browser Traveller | Trepr';
    var self = this;
    $.ajaxSetup({
      headers: {
        Authorization: 'Bearer ' + this.$store.state.token,
      },
      beforeSend: function () {
        $('#general-ajax-load').fadeIn();
      },
      complete: function () {
        $('#general-ajax-load').fadeOut();
      },
      success: function () {
        $('#general-ajax-load').fadeOut();
      },
    });
    if (window.moment == undefined) {
      window.moment = require('moment');
    }
    // console.log("my moment")
    if (this.$route.query.date != undefined) {
      start = moment(this.$route.query.date.substring(0, 10), 'YYYY-MM-DD');
      end = moment(
        this.$route.query.date.substring(12, this.$route.query.date.length),
        'YYYY-MM-DD'
      );
    }

    $('#rangeDatetimePicker').daterangepicker(
      {
        timePicker: false,
        timePicker24Hour: false,
        // timePickerSeconds: true,
        startDate: start,
        endDate: end,
        autoApply: true,
        ranges: {
          '1 Days': [moment(), moment().add(1, 'days')],
          '3 Days': [moment(), moment().add(3, 'days')],
          '1 Week': [moment(), moment().add(7, 'days')],
          '2 Weeks': [moment(), moment().add(14, 'days')],
          '3 Weeks': [moment(), moment().add(21, 'days')],
          '1 Months': [moment(), moment().add(1, 'months')],
          '2 Months': [moment(), moment().add(2, 'months')],
          '3 Months': [moment(), moment().add(3, 'months')],
          '6 Months': [moment(), moment().add(6, 'months')],
        },
        locale: {
          format: 'YYYY-MM-DD',
        },
      },
      cb
    );
    console.log('start' + start);
    cb(start, end);
    //------------------search button click
    $('#btnResultList').click(function () {
      if ($(window).width() <= 991) {
        //" only hide search block after click with mobile, tablet in vertical mode
        if ($('#searchResultList').hasClass('d-none')) {
          $('#searchResultList').removeClass('d-none');
        }
        if (!$('#searchResultMap').hasClass('d-none')) {
          $('#searchResultMap').addClass('d-none');
        }
        $('#btnResultList').toggleClass('btn-danger');
        $('#btnResultMap').toggleClass('btn-danger');
      }
    });
    $('#btnResultMap').click(function () {
      if ($(window).width() <= 991) {
        //" only hide search block after click with mobile, tablet in vertical mode
        if ($('#searchResultMap').hasClass('d-none')) {
          $('#searchResultMap').removeClass('d-none');
        }
        if (!$('#searchResultList').hasClass('d-none')) {
          $('#searchResultList').addClass('d-none');
        }
        $('#btnResultList').toggleClass('btn-danger');
        $('#btnResultMap').toggleClass('btn-danger');
      }
    });
    //--------------end search button click

    var inputOriginalLocation = document.getElementById('originalLocation');
    var inputDestinationLocation = document.getElementById('destinationLocation');
    // MyAutocomplete.initData(this);
    // this.$data.ajax.originalLocationLoad
    // let objCommonAutocomplete =
    autocomplete({
      input: inputOriginalLocation,
      minLength: 2,
      disableAutoSelect: true,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'original'),
      className: classNameAuto,
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'original'),
      onSelect: function (item) {
        console.log('select data autocomple orignal');
        console.log(item.coordinates);
        let tmpCoordinates = item.coordinates;
        self.$data.initGmap.center.lat = tmpCoordinates.lat;
        self.$data.initGmap.center.long = tmpCoordinates.lon;
        inputOriginalLocation.value = '(' + item.code + ') ' + item.name;
        document.getElementById('origin_type').value = item.type;
        document.getElementById('origin_code').value = item.code;
      },
    });
    // let objCommonAutocomplete =
    autocomplete({
      input: inputDestinationLocation,
      minLength: 2,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'destination'),
      className: classNameAuto,
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'destination'),
      onSelect: function (item) {
        inputDestinationLocation.value = '(' + item.code + ') ' + item.name;
        document.getElementById('destination_type').value = item.type;
        document.getElementById('destination_code').value = item.code;
      },
    });

    // $(window).scroll(function(){
    if (this.$data.isFromHomepage == true) {
      btnSearchClickHandler(self);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/new/page/search';
/*@import "../../../../node_modules/bootstrap/scss/bootstrap";*/
</style>
